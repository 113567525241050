import React from 'react';
import cn from 'classnames';

import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';

import './SavedContentPageWrapper.css';

interface SavedContentPageWrapperProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isPagination?: boolean;
}

const SavedContentPageWrapper: React.FC<SavedContentPageWrapperProps> = ({
  children,
  isLoading,
  isPagination,
}) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center bg-cement-100 saved-content-page-wrapper lg:px-[2rem]',
        isPagination ? 'max-h-[620px] overflow-y-scroll' : '',
      )}>
      <div className="min-h-[calc(100vh-200px)] max-w-[96%] w-[100%] lg:w-[70.625rem] py-6 lg:py-10">
        {isLoading ? (
          <div className="flex justify-center p-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
};

export default SavedContentPageWrapper;
