import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useApiClient } from '@/hooks/useApiClient';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import { Favorite } from '@/types';
import { decodeString } from '@/utilities/textHelpers';
import FavoritesModal from '@components/AddFavoriteToCollectionModal/FavoritesModal';
import { AddOrRenameCollectionModal as RemnameCollectionModal } from '@components/AddOrRenameCollectionModal';
import Button, { ButtonVariant } from '@components/Button';
import ExportModal from '@components/ExportModal/ExportModal';
import FavoritesTable from '@components/FavoritesTable';
import Footer from '@components/Footer';
import { Icon, IconName } from '@components/Icon';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import PostFavoritedModals from '@components/PostFavoritedModals';
import { RemoveCollectionModal } from '@components/RemoveCollectionModal';
import { RemoveFavoritesFromCollectionModal } from '@components/RemoveFavoritesFromCollectionModal';
import RemoveUnavailableListingsModal from '@components/RemoveUnavailableListingsModal/RemoveUnavailableListingsModal';
import SavedContentPageWrapper from '@components/SavedContentPageWrapper';
import { sortNumericValues } from '@components/SearchResults/SearchResults.utils';
import { ShareCollectionModal } from '@components/ShareCollectionModal';
import { ShareListingModal } from '@components/ShareListingModal';
import Sort from '@components/Sort';
import { FilterOption } from '@components/Sort/Sort.types';
import { Typography } from '@components/Typography';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import MapModal from './MapModal';
import './MapModal.css';
import {
  getPaginatedCollectionFavorites,
  getAllCollectionFavorites,
} from '@/api/favorite-collections';
import { marketplaceApiInstance } from '@/lib/axios';
import CreateReportModal from '@components/CreateReportModal';
import Pagination from '@components/Pagination/Pagination';
const A_TO_Z = 'aToZ';
const Z_TO_A = 'zToA';
const SQF_I_X = 'SiTox';
const SQF_X_I = 'SxToi';
const filterOptions: FilterOption[] = [
  {
    label: 'Name (A - Z)',
    value: A_TO_Z,
  },
  {
    label: 'Name (Z - A)',
    value: Z_TO_A,
  },

  {
    label: 'Square Footage (Min - Max)',
    value: SQF_I_X,
  },
  {
    label: 'Square Footage (Max - Min)',
    value: SQF_X_I,
  },
];

export const CollectionDetails: React.FC = () => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { id: favoriteCollectionId } = useParams();
  const { getFavoriteCollectionById, getFlyer } = useApiClient();
  const {
    data: favoriteCollection,
    isLoading,
    isError,
    refetch,
  } = getFavoriteCollectionById(favoriteCollectionId!);
  const [sortedFavorites, setSortedFavorites] = useState<Favorite[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption | undefined>(filterOptions[0]);
  const isBigScreen = useIsBigScreen();

  const [contextMenuFavorite, setContextMenuFavorite] = useState<Favorite | null>(null);
  const [selectedFavorites, setSelectedFavorites] = useState<Favorite[]>([]);
  const [unavailableListings, setUnavailableListings] = useState<Favorite[]>([]);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showRenameCollectionModal, setShowRenameCollectionModal] = useState(false);
  const [showRemoveCollectionModal, setShowRemoveCollectionModal] = useState(false);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [showShareCollectionModal, setShowShareCollectionModal] = useState(false);
  const [showShareListingModal, setShowShareListingModal] = useState(false);
  const [showUnavailableListingsModal, setShowUnavailableListingsModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalFavoriteCount, setTotalFavoriteCount] = useState(0);
  const [paginatedFavorites, setPaginatedFavorites] = useState<Favorite[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isPaginatedFavoritesLoading, setIsPaginatedFavoritesLoading] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [allCollectionFavorite, setAllCollectionFavorite] = useState<Favorite[]>([]);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [selectedFavoriteListings, setSelectedFavoriteListings] = useState<string[]>([]);
  const [removeSingleRow, setRemoveSingleRow] = useState(false);
  const debounceTimeout = useRef<any>(null);

  const [showRemoveFavoritesFromCollectionModal, setShowRemoveFavoritesFromCollectionModal] =
    useState(false);
  const [
    showCollectionGenerateAvailabilityReportsModal,
    setShowCollectionGenerateCollectionAvailabilityReportsModal,
  ] = useState(false);
  const [
    showSelectionGenerateAvailabilityReportsModal,
    setShowSelectionGenerateAvailabilityReportsModal,
  ] = useState(false);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToast();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const updateShowExportModal = (state: boolean) => {
    setShowExportModal(state);
  };
  const handleDownloadFlyer = async (favorite: Favorite) => {
    const data = await getFlyer(favorite.listingId);

    const link = document.createElement('a');

    link.href = URL.createObjectURL(data);
    link.download = `flyer-${favorite.listingId}-${new Date().getTime()}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));

    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      description: `Listing Flyer Downloaded`,
      title: 'Success',
      type: 'success',
    });
  };

  const { removeFavoritesFromFavoriteCollection } = useUserFavoriteCollections();

  const decodedCollectionName = favoriteCollection?.name
    ? decodeString(favoriteCollection.name)
    : '';

  const getSelectedFavoritesIds = (selectedFavorites: Favorite[]) =>
    selectedFavorites.map((favorite) => favorite.favoriteId);

  useEffect(() => {
    const notAvailable = favoriteCollection?.favorites.filter((favorite) => !favorite.isVisible);
    setUnavailableListings(notAvailable ?? []);
  }, [favoriteCollection]);

  const handlePageSizeChange = (newSize: number) => {
    setSelectedPageNumber(1);
    setPageSize(newSize);
  };

  useEffect(() => {
    setIsPaginatedFavoritesLoading(true);
    const delayedFunction = () => {
      getPaginatedCollectionFavorites(marketplaceApiInstance, {
        favoriteCollectionId,
        pageNumber: selectedPageNumber,
        pageSize: pageSize,
        searchQuery: searchQuery,
      }).then((data: any) => {
        if (data.status === 200) {
          setTotalFavoriteCount(data.data.totalCount);
          setPaginatedFavorites(data.data.favorites);
          setIsPaginatedFavoritesLoading(false);
        }
      });
    };
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(delayedFunction, 1000);
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    getPaginatedCollectionFavorites(marketplaceApiInstance, {
      favoriteCollectionId,
      pageNumber: selectedPageNumber,
      pageSize: pageSize,
      searchQuery: searchQuery,
    }).then((data: any) => {
      if (data.status === 200 && data.data.favorites.length > 0) {
        setTotalFavoriteCount(data.data.totalCount);
        setPaginatedFavorites(data.data.favorites);
        setIsPaginatedFavoritesLoading(false);
      }
    });
  }, [selectedPageNumber, pageSize, favoriteCollection]);

  useEffect(() => {
    if (isSelectAll && favoriteCollectionId) {
      getAllCollectionFavorites(marketplaceApiInstance, favoriteCollectionId, searchQuery).then(
        (data: any) => {
          if (data.status === 200) {
            setAllCollectionFavorite(data.data);
          }
        },
      );
    }
  }, [isSelectAll]);

  useEffect(() => {
    // Check if the text overflows the container
    const textContainer = textContainerRef.current;
    if (textContainer) {
      setIsTooltipVisible(textContainer.scrollWidth > textContainer.clientWidth);
    }
  }, [favoriteCollection]);

  if (isError || (!isLoading && !favoriteCollection)) {
    return <Navigate to="/not-found" />;
  }

  const handleConfirmRemoveUnavailableListings = async () => {
    await removeFavoritesFromFavoriteCollection(
      favoriteCollectionId!,
      unavailableListings.map((x) => x.favoriteId),
    );
    refetch();
    setShowUnavailableListingsModal(false);
  };

  useEffect(() => {
    const FilterValue = selectedFilter?.value;
    let result = [...(favoriteCollection?.favorites ?? [])]; // Create a shallow copy of the original listings array
    switch (FilterValue) {
      case A_TO_Z:
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case Z_TO_A:
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case SQF_I_X:
        result.sort((a, b) => sortNumericValues(a?.sortableSize, b?.sortableSize));
        break;
      case SQF_X_I:
        result.sort((a, b) => sortNumericValues(b?.sortableSize, a?.sortableSize));
        break;
      case 'Default':
        result;
        break;
      default:
        break;
    }
    setSortedFavorites(result);
  }, [selectedFilter, favoriteCollection]);
  const renderActions = () => (
    <div className="flex float-right">
      <Button
        Icon={<Icon name={IconName.PLUS} />}
        label="Add Listings"
        onClick={() => setShowFavoriteModal(true)}
        variant={ButtonVariant.DEFAULT_OUTLINE}
        classNames="md:mr-4 min-w-max"
      />
      <Button
        disabled={favoriteCollection?.favorites.filter((x) => x.isVisible).length === 0}
        Icon={<Icon name={IconName.MAP_SETTINGS} />}
        label="View On Map"
        onClick={() => setShowMapModal(true)}
        variant={ButtonVariant.DEFAULT_OUTLINE}
        classNames="mr-2 min-w-max"
      />
    </div>
  );

  return (
    <>
      <SavedContentPageWrapper isLoading={isLoading} isPagination={!isBigScreen ? true : false}>
        <div className="mb-12 md:mb-0">
          <div
            className={cn([
              'flex flex-col justify-between h-[3rem] mb-3',
              unavailableListings.length > 0 ? 'sm:h-[5.625rem]' : 'sm:h-[3.625rem]',
            ])}>
            <div className="flex items-center justify-between h-[3.125rem]">
              <div className="flex items-center collection-title">
                <div
                  key="collection-name"
                  className="collection-container"
                  data-tooltip-id="collection-name-tooltip"
                  data-tooltip-target="tooltip-default">
                  <Typography
                    className="mr-4 my-[0.5625rem] break-all truncate cursor-pointer"
                    variant="title"
                    ref={textContainerRef}>
                    {decodedCollectionName}
                  </Typography>
                  {isTooltipVisible && (
                    <Tooltip id="collection-name-tooltip" place="top">
                      <Typography variant="body-4">{decodedCollectionName}</Typography>
                    </Tooltip>
                  )}
                </div>
                <Menu
                  align="start"
                  menuClassName="rounded-lg bg-white-400 shadow w-52 px-5 py-2 z-50"
                  menuButton={
                    <MenuButton className="collection-name-menu">
                      <Icon name={IconName.MORE_VERTICAL} />
                    </MenuButton>
                  }>
                  <MenuItem
                    className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                    onClick={() => setShowRemoveCollectionModal(true)}>
                    <Icon name={IconName.TRASH} />
                    <Typography variant="button">Delete</Typography>
                  </MenuItem>
                  <MenuItem
                    className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                    onClick={() => setShowRenameCollectionModal(true)}>
                    <Icon name={IconName.EDIT} />
                    <Typography variant="button">Rename</Typography>
                  </MenuItem>
                  <MenuItem
                    className={cn([
                      'flex items-center space-x-2 py-2 cursor-pointer',
                      (favoriteCollection?.favorites.length ?? 0) === 0
                        ? 'cursor-not-allowed opacity-60 hover:text-base-black'
                        : 'hover:text-rust-100',
                    ])}
                    disabled={(favoriteCollection?.favorites.length ?? 0) === 0}
                    onClick={() => {
                      setShowShareCollectionModal(true);
                    }}>
                    <Icon name={IconName.EXTERNAL_LINK} />
                    <Typography variant="button">Share Collection</Typography>
                  </MenuItem>
                  <div>
                    <div
                      data-tooltip-id="max-listings-tooltip"
                      data-tooltip-target="tooltip-default">
                      <MenuItem
                        className={cn([
                          'flex items-center space-x-2 py-2 cursor-pointer',
                          (favoriteCollection?.favorites.length ?? 0) === 0
                            ? 'cursor-not-allowed opacity-60 hover:text-base-black'
                            : 'hover:text-rust-100',
                        ])}
                        disabled={(favoriteCollection?.favorites.length ?? 0) === 0}
                        onClick={() =>
                          setShowCollectionGenerateCollectionAvailabilityReportsModal(true)
                        }>
                        <Icon name={IconName.LEASING_AND_OCCUPANCY} />
                        <Typography variant="button">Create Report</Typography>
                      </MenuItem>
                    </div>
                  </div>
                </Menu>
              </div>
              {!isBigScreen && (favoriteCollection?.favorites.length ?? 0) > 0 && (
                <Sort
                  name="sort favorites in collection"
                  filterOptions={filterOptions}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  customButtonLabel="Sort"
                  showCustomButtonLabel={isSmallScreen}
                />
              )}

              {isBigScreen &&
                favoriteCollection?.favorites &&
                favoriteCollection.favorites.length > 0 &&
                renderActions()}
            </div>

            {unavailableListings.length > 0 && (
              <Button
                buttonClassNames="!text-rust-100 hover:!text-rust-300"
                Icon={<Icon name={IconName.ALERT_TRIANGLE} />}
                label={`Remove ${unavailableListings.length} Unavailable Listings`}
                onClick={() => setShowUnavailableListingsModal(true)}
                variant={ButtonVariant.NO_FILL}
              />
            )}
          </div>
          {isLoading ||
          (favoriteCollection?.favorites.length > 0 && sortedFavorites.length == 0) ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : sortedFavorites.length > 0 ? (
            <FavoritesTable
              label={decodedCollectionName}
              data={paginatedFavorites}
              allFavorites={favoriteCollection?.favorites}
              onSelectionChange={(favorites) => setSelectedFavorites(favorites)}
              onAddToCollection={() => setShowCollectionsModal(true)}
              onRemove={() => setShowRemoveFavoritesFromCollectionModal(true)}
              onCreateReport={() => setShowSelectionGenerateAvailabilityReportsModal(true)}
              numberOfSelectedFavorites={selectedFavorites.length}
              selectedPageNumber={selectedPageNumber}
              pageSize={pageSize}
              updatePageSize={(size) => handlePageSizeChange(size)}
              updateSelectedPageNumber={(page) => setSelectedPageNumber(page)}
              totalFavoriteCount={totalFavoriteCount}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              isPaginatedFavoritesLoading={isPaginatedFavoritesLoading}
              setIsPaginatedFavoritesLoading={setIsPaginatedFavoritesLoading}
              isSelectAll={isSelectAll}
              setIsSelectAll={(value) => setIsSelectAll(value)}
              selectedListings={selectedListings}
              setSelectedListings={(selectedListings) => setSelectedListings(selectedListings)}
              selectedFavoriteListings={selectedFavoriteListings}
              setSelectedFavoriteListings={(selectedFavoriteListings) =>
                setSelectedFavoriteListings(selectedFavoriteListings)
              }
              moreActionsCell={({ row }) => (
                <Menu
                  menuClassName="rounded-lg bg-white-400 sm:!static md:!absolute shadow w-[14.0625rem] px-5 py-2 md:!left-[-26rem] lg:!left-[-13.725rem]"
                  menuButton={
                    <MenuButton>
                      <Icon
                        classNames="text-white-100 md:text-cement-500"
                        name={IconName.MORE_VERTICAL}
                      />
                    </MenuButton>
                  }>
                  {row.original.isVisible && (
                    <>
                      <MenuItem
                        className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                        onClick={() => {
                          handleDownloadFlyer(row.original);
                        }}>
                        <Icon name={IconName.DOWNLOAD} />
                        <Typography variant="button">Download Flyer</Typography>
                      </MenuItem>
                      <MenuItem
                        className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                        onClick={() => {
                          setRemoveSingleRow(true);
                          setContextMenuFavorite(row.original);
                          setShowCollectionsModal(true);
                        }}>
                        <Icon name={IconName.ADD_FOLDER} />
                        <Typography variant="button">Add to Collection</Typography>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                    onClick={() => {
                      setContextMenuFavorite(row.original);
                      setShowRemoveFavoritesFromCollectionModal(true);
                      setRemoveSingleRow(true);
                    }}>
                    <Icon name={IconName.TRASH} />
                    <Typography variant="button">Remove from Collection</Typography>
                  </MenuItem>
                  {row.original.isVisible && (
                    <MenuItem
                      className="flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100"
                      onClick={() => {
                        setContextMenuFavorite(row.original);
                        setShowShareListingModal(true);
                      }}>
                      <Icon name={IconName.EXTERNAL_LINK} />
                      <Typography variant="button">Share Listing</Typography>
                    </MenuItem>
                  )}
                </Menu>
              )}
            />
          ) : (
            <div className="flex flex-col justify-end items-center">
              <div className="sm:absolute sm:top-[40vh] md:relative md:top-4 sm:mx-2">
                <Typography className="mb-2 text-center" variant="h6">
                  You don&apos;t have any listings in this collection yet.
                </Typography>
                <Typography className="mb-10 text-center" variant="body-2">
                  Get started by adding some favorites to this collection.
                </Typography>
                <div className="flex justify-center">
                  <Button
                    classNames="sm:absolute sm:top-[37vh] md:relative md:top-0"
                    buttonClassNames="lg:w-[28.125rem] w-[18rem]"
                    Icon={<Icon name={IconName.ADD_FOLDER} />}
                    label="Add Favorites To Collection"
                    variant={ButtonVariant.PRIMARY_ONE}
                    onClick={() => setShowFavoriteModal(true)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </SavedContentPageWrapper>
      {!isBigScreen && paginatedFavorites.length > 0 && (
        <Pagination
          selectedPageNumber={selectedPageNumber}
          pageSize={pageSize}
          updatePageSize={(size) => setPageSize(size)}
          updateSelectedPageNumber={(page) => setSelectedPageNumber(page)}
          totalRows={totalFavoriteCount}
          dataCount={paginatedFavorites.length}
          bottom={true}
        />
      )}

      {showFavoriteModal && favoriteCollectionId && (
        <FavoritesModal
          favoriteCollectionId={favoriteCollectionId}
          onClose={() => {
            refetch();
            setShowFavoriteModal(false);
          }}
        />
      )}
      {favoriteCollection && (
        <>
          {showMapModal && (
            <MapModal
              favoriteCollection={favoriteCollection}
              onClose={() => setShowMapModal(false)}
            />
          )}

          {showRenameCollectionModal && (
            <RemnameCollectionModal
              show={showRenameCollectionModal}
              favoriteCollection={favoriteCollection}
              onConfirm={() => {
                refetch();
                setShowRenameCollectionModal(false);
              }}
              onClose={() => setShowRenameCollectionModal(false)}
            />
          )}

          {showRemoveCollectionModal && (
            <RemoveCollectionModal
              show={showRemoveCollectionModal}
              favoriteCollection={favoriteCollection}
              onConfirm={() => {
                navigate('/bookmarks/collections');
                setShowRemoveCollectionModal(false);
              }}
              onClose={() => setShowRemoveCollectionModal(false)}
            />
          )}

          {showRemoveFavoritesFromCollectionModal && (
            <RemoveFavoritesFromCollectionModal
              show={showRemoveFavoritesFromCollectionModal}
              favoriteCollection={favoriteCollection}
              favorites={
                isSelectAll && allCollectionFavorite.length > 0 && !removeSingleRow
                  ? allCollectionFavorite
                  : contextMenuFavorite && removeSingleRow
                  ? [contextMenuFavorite]
                  : selectedFavorites
              }
              onConfirm={() => {
                refetch();

                setContextMenuFavorite(null);
                setShowRemoveFavoritesFromCollectionModal(false);
              }}
              onClose={() => {
                setContextMenuFavorite(null);
                setShowRemoveFavoritesFromCollectionModal(false);
              }}
              allCollectionFavorite={allCollectionFavorite}
              selectedFavoriteListings={selectedFavoriteListings}
              setSelectedListings={(selectedListings) => setSelectedListings(selectedListings)}
              setSelectedFavoriteListings={(selectedFavoriteListings) =>
                setSelectedFavoriteListings(selectedFavoriteListings)
              }
              removeSingleRow={removeSingleRow}
              setRemoveSingleRow={(removeSingleRow) => setRemoveSingleRow(removeSingleRow)}
              isSelectAll={isSelectAll}
              setSelectedPageNumber={(selectedPageNumber) =>
                setSelectedPageNumber(selectedPageNumber)
              }
            />
          )}

          {showCollectionGenerateAvailabilityReportsModal && (
            <CreateReportModal
              listingGroups={[
                {
                  name: favoriteCollection.name,
                  listingIds: favoriteCollection.favorites.map((x) => x.listingId),
                },
              ]}
              onConfirm={() => {
                setShowCollectionGenerateCollectionAvailabilityReportsModal(false);
              }}
              onClose={() => {
                setShowCollectionGenerateCollectionAvailabilityReportsModal(false);
              }}
              fromCollections={false}
            />
          )}

          {showSelectionGenerateAvailabilityReportsModal && (
            <CreateReportModal
              listingGroups={[
                {
                  name: selectedListings.length == 0 ? favoriteCollection.name : '',
                  listingIds:
                    isSelectAll && allCollectionFavorite.length > 0
                      ? allCollectionFavorite.map((x) => x.listingId)
                      : selectedListings.length > 0
                      ? selectedListings
                      : favoriteCollection?.favorites.map((x) => x.listingId),
                },
              ]}
              onConfirm={() => {
                setShowSelectionGenerateAvailabilityReportsModal(false);
              }}
              onClose={() => {
                setShowSelectionGenerateAvailabilityReportsModal(false);
              }}
              fromCollections={selectedListings.length == 0}
            />
          )}

          {showCollectionsModal && (
            <PostFavoritedModals
              addToCollectionFlow
              favoriteListingIds={
                isSelectAll && allCollectionFavorite.length > 0 && !removeSingleRow
                  ? getSelectedFavoritesIds(allCollectionFavorite)
                  : contextMenuFavorite && removeSingleRow
                  ? [contextMenuFavorite.favoriteId]
                  : selectedFavoriteListings
              }
              onClose={() => {
                refetch().catch((error) =>
                  console.error('refetching collection failed', error.message),
                );
                setContextMenuFavorite(null);
                setShowCollectionsModal(false);
              }}
            />
          )}
        </>
      )}

      {showShareCollectionModal && favoriteCollectionId && (
        <ShareCollectionModal
          favoriteCollectionId={favoriteCollectionId}
          show={showShareCollectionModal}
          listingIds={favoriteCollection?.favorites.map((f) => f.listingId)!}
          onClose={() => {
            setShowShareCollectionModal(false);
          }}
        />
      )}

      {showShareListingModal && contextMenuFavorite && (
        <ShareListingModal
          listingId={contextMenuFavorite.listingId}
          propertyType={contextMenuFavorite.propertyType}
          show={showShareListingModal}
          onClose={() => {
            setContextMenuFavorite(null);
            setShowShareListingModal(false);
          }}
        />
      )}

      {showUnavailableListingsModal && (
        <RemoveUnavailableListingsModal
          unavailableListings={unavailableListings}
          onClose={() => setShowUnavailableListingsModal(false)}
          onConfirm={handleConfirmRemoveUnavailableListings}
          collectionName={decodedCollectionName}
        />
      )}

      <ExportModal
        listingIds={sortedFavorites?.map((listing) => listing.listingId)}
        showExportModal={showExportModal}
        updateShowExportModal={updateShowExportModal}
        exportLength={sortedFavorites?.length}
      />

      {!isBigScreen &&
      favoriteCollection?.favorites &&
      favoriteCollection.favorites.length > 0 &&
      !removeSingleRow ? (
        <div className="mobile-actions">{renderActions()}</div>
      ) : (
        <Footer />
      )}
    </>
  );
};

export default CollectionDetails;
